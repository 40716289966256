<template>
    <div>
        <div class="flex flex-col">
            <div class="relative " @mouseover="hover = true" @mouseleave="hover = false">
                <img :src="sourceImage" class="rounded-t-lg aspect-auto w-80" />
                <Transition>
                    <div v-if="hover"
                        class="absolute top-0 flex items-center justify-center w-full h-full bg-gray-800 rounded-t-lg cursor-pointer bg-opacity-90"
                        @click="toggler = !toggler">
                        <p>Open Gallery</p>
                    </div>
                </Transition>
            </div>
            <div class="py-4 text-center bg-gray-600 rounded-b-lg bg-opacity-60">
                <a :href="linkUrl" target="_blank" v-if="link" class="flex flex-row items-center justify-center gap-2">
                    <p>{{ title }} </p>
                    <svg class="w-6 h-6 text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M18 14v4.8a1.2 1.2 0 0 1-1.2 1.2H5.2A1.2 1.2 0 0 1 4 18.8V7.2A1.2 1.2 0 0 1 5.2 6h4.6m4.4-2H20v5.8m-7.9 2L20 4.2" />
                    </svg>
                </a>
                <p v-else>{{ title }}</p>
            </div>
        </div>
        <FsLightbox :toggler="toggler" :sources="sources" />
    </div>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";

export default {
    components: { FsLightbox },
    props: {
        title: String,
        link: Boolean,
        linkUrl: String,
        sources: Array,
        sourceImage: String,
    },
    data() {
        return {
            hover: false,
            toggler: false,
        }
    }
}
</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>