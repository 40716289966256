<template>
    <div>
        <div class="flex flex-col">
            <div class="relative " @mouseover="hover = true" @mouseleave="hover = false">
                <img :src="sourceImage" class="rounded-lg aspect-auto" :class="{'w-80': orientation === 'landscape', 'w-40': orientation === 'portrait'}" />
                <Transition>
                    <div v-if="hover"
                        class="absolute top-0 flex items-center justify-center w-full h-full bg-gray-800 rounded-lg cursor-pointer bg-opacity-90"
                        @click="toggler = !toggler">
                        <p>Open Gallery</p>
                    </div>
                </Transition>
            </div>
        </div>
        <FsLightbox :toggler="toggler" :sources="sources" />
    </div>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";

export default {
    components: { FsLightbox },
    props: {
        sources: Array,
        sourceImage: String,
        orientation: String,
    },
    data() {
        return {
            hover: false,
            toggler: false,
        }
    }
}
</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>