import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutMe from '../views/AboutMe.vue'
import CareerView from '../views/CareerView.vue'
import PageNotFound from '../views/PageNotFound.vue'
import OfferView from '../views/OfferView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutMe
  },
  {
    path: '/career',
    name: 'career',
    component: CareerView
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound
  },
  {
    path: '/offer',
    name: 'offer',
    component: OfferView
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
