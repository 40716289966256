<template>
    <div class="flex flex-col items-center m-auto text-gray-200 lg:flex-row lg:justify-center lg:gap-24">
        <img class="min-[230px]:max-[370px]:w-48 mt-4 lg:mt-0 rounded-full border-4 w-72 lg:w-96"
            src="../assets/G0VMGjZwfH.png" alt="image description">
        <div class="p-2 mx-1 mt-5 text-center lg:mx-5 lg:text-left lg:mt-0 lg:p-0">
            <p
                class="min-[230px]:max-[370px]:text-xl text-3xl sm:text-4xl font-extrabold text-gray-200  lg:text-5xl xl:text-6xl mb-10">
                Hi. I'm Dominik.
            </p>

            <div class="grid items-center justify-center grid-cols-3 gap-4">
                <p
                    class="min-[230px]:max-[370px]:text-xl text-3xl sm:text-4xl font-extrabold text-gray-200 lg:text-5xl xl:text-6xl my-1 py-3 text-ellipsis">
                    I am</p>
                <p
                    class="min-[230px]:max-[370px]:text-xl text-3xl sm:text-4xl font-extrabold text-gray-200 lg:text-5xl xl:text-6xl my-1 py-3 text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-left col-span-2">
                    Technical Test Consultant
                </p>

                <p
                    class="min-[230px]:max-[370px]:text-xl text-3xl sm:text-4xl font-extrabold text-gray-200 lg:text-5xl xl:text-6xl my-1 py-3 text-ellipsis">
                    part-time</p>
                <p
                    class="min-[230px]:max-[370px]:text-xl text-3xl sm:text-4xl font-extrabold text-gray-200 lg:text-5xl xl:text-6xl my-1 py-3 text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-left col-span-2">
                    Fullstack
                    Developer
                </p>

                <p
                    class="min-[230px]:max-[370px]:text-xl text-3xl sm:text-4xl font-extrabold text-gray-200 lg:text-5xl xl:text-6xl my-1 py-3">
                    but mostly</p>
                <p
                    class="min-[230px]:max-[370px]:text-xl text-3xl sm:text-4xl font-extrabold text-gray-200 lg:text-5xl xl:text-6xl my-1 py-3 text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-left col-span-2">
                    Automation
                    Enthusiast
                </p>
            </div>

        </div>
    </div>
    <div class="flex flex-col items-center justify-center m-auto my-5 text-gray-200 lg:flex-row">
        <router-link to="offer"
            class="min-[230px]:max-[370px]:text-sm text-gray-200 border rounded-lg px-5 py-2 mt-2 lg:mt-8 font-semibold bg-gray-800 hover:bg-gray-200 hover:text-gray-800">What Do I Offer</router-link>
    </div>
</template>

<script>
export default {
    name: "HomeView",
};
</script>