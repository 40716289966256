<template lang="">
    <div>
        
    </div>
</template>
<script>
export default {
    created() {
        this.$router.push({ path: '/' })
    }
}
</script>