<template>
    <div class="px-5">
        <div
            class="flex flex-col justify-center max-w-6xl p-8 mx-auto my-5 text-lg text-justify text-gray-200 bg-gray-700 md:text-xl gap-y-8 bg-opacity-40 rounded-3xl ">
            <p>Let me introduce myself and share a bit about my interests beyond my
                professional life.</p>

            <p>First and foremost, I am a devoted family man. Spending quality time with my wife and our little boy brings
                me
                immense joy and fulfillment. They are my biggest source of inspiration and motivation in life.</p>

            <p>When it comes to hobbies, I have a diverse range of interests. I am an avid gamer, both in the digital and
                analog
                realms. Video games have always captivated me, offering immersive experiences and storytelling adventures. I
                particularly enjoy exploring richly crafted worlds and embarking on epic quests, making "Lord of the Rings"
                one
                of
                my all-time favorite movies. The intricate storytelling, captivating characters, and breathtaking visuals
                truly
                transport me to another realm.</p>

            <p>Aside from virtual adventures, I also love engaging in physical activities. One of my favorite sports is
                squash.
                The
                fast-paced nature of the game and the strategic elements involved always keep me on my toes. It's a
                fantastic
                way to
                stay active and unwind after a long day of programming.</p>

            <p>Speaking of programming, it's not just my job but also one of my passions. The ever-evolving world of
                technology
                never ceases to amaze me. I find great satisfaction in solving complex problems and building innovative
                solutions.
                As a programmer, I'm particularly interested in the intersection of technology and graphics, constantly
                seeking
                ways
                to create visually stunning and immersive experiences.</p>

            <p>Through this website, I aim to showcase not only my professional expertise but also provide a glimpse into
                the
                multifaceted aspects of my life. So, feel free to explore and get to know me better.</p>

            <p>Thank you for visiting, and I look forward to connecting with you soon!</p>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        window.scrollTo(0, 0);
    },
}
</script>