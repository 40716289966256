<template>
    <div>
        <div class="flex items-center justify-center w-full text-lg text-gray-200">
            <div class="flex flex-wrap items-stretch mt-10 gap-x-28 gap-y-12">

                <div
                    class="flex flex-col items-center justify-start max-w-xl p-5 text-center duration-300 bg-gray-700 border border-gray-200 rounded-lg hover:scale-105 bg-opacity-40">
                    <p
                        class="col-span-2 py-1 my-1 text-4xl font-extrabold text-transparent text-gray-200 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                        TESTING SERVICES</p>
                    <p class="mt-10"><span class="font-bold text-emerald-400">Test Automation:</span> Design and
                        implementation of automated testing solutions.</p>
                    <hr class="w-full h-px mx-2 my-5 bg-gray-600 border-0">
                    <p><span class="font-bold text-emerald-400">Technical Test Consultancy:</span> Strategic guidance and
                        tailored solutions for testing challenges.</p>
                    <hr class="w-full h-px mx-2 my-5 bg-gray-600 border-0">
                    <p><span class="font-bold text-emerald-400">CI/CD and DevOps Support:</span> Assistance with CI/CD
                        pipelines and DevOps practices to enhance testing
                        processes and workflow efficiency.</p>
                </div>

                <div
                    class="flex flex-col items-center justify-start max-w-xl p-5 text-center duration-300 bg-gray-700 border border-gray-200 rounded-lg hover:scale-105 bg-opacity-40">
                    <p
                        class="col-span-2 py-1 my-1 text-4xl font-extrabold text-transparent text-gray-200 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                        DEVELOPMENT SERVICES</p>
                    <p class="mt-10"><span class="font-bold text-emerald-400">Web Development:</span> Creating responsive
                        and visually captivating websites.</p>
                    <hr class="w-full h-px mx-2 my-5 bg-gray-600 border-0">
                    <p><span class="font-bold text-emerald-400">Backend Application Development:</span> Building robust
                        backend systems and APIs.</p>
                    <hr class="w-full h-px mx-2 my-5 bg-gray-600 border-0">
                    <p><span class="font-bold text-emerald-400">Fullstack Development:</span> End-to-end solutions covering
                        frontend and backend, with integration of CI/CD
                        pipelines and DevOps practices for seamless development, testing, and deployment.</p>
                </div>

            </div>
        </div>

        <div class="top-0 flex flex-col items-center justify-center mt-16 mb-6 text-gray-200">
            <p
                class="col-span-2 py-1 my-1 text-4xl font-extrabold text-left text-transparent text-gray-200 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 md:mt-10">
                WEBSITES</p>
            <p class="col-span-2 py-1 my-1 text-lg italic font-extrabold text-gray-500 md:mt-1">
                Click for more screenshots</p>
        </div>
        <div class="flex flex-wrap items-end justify-center w-full gap-10 text-gray-200">
            <PortfolioWeb :link-url="'https://shop.eevree.com'" :link="true" :title="'eeVRee Shop'"
                :source-image="require('@/assets/portfolio/eshop/cK9c2TSb6e.png')"
                :sources="[require('@/assets/portfolio/eshop/cK9c2TSb6e.png'), require('@/assets/portfolio/eshop/9LaCw9Zkg5.png'), require('@/assets/portfolio/eshop/dBBQgYmAt1.png'), require('@/assets/portfolio/eshop/ka1PtmAWzS.png')]" />

            <PortfolioWeb :link="false" :title="'eeVRee Admin Dashboard'"
                :source-image="require('@/assets/portfolio/admin/UtymPgQI8Z.png')"
                :sources="[require('@/assets/portfolio/admin/UtymPgQI8Z.png'), require('@/assets/portfolio/admin/4FkawGGqV0.png'), require('@/assets/portfolio/admin/08N9PTjCji.png'), require('@/assets/portfolio/admin/bBZODWuSwD.png'), require('@/assets/portfolio/admin/KCO6BtlUJX.png'), require('@/assets/portfolio/admin/ra8oVOnoD0.png')]" />

            <PortfolioWeb :link="false" :title="'Impactee.AI'"
                :source-image="require('@/assets/portfolio/impactee/Gcm6iuDVJ9.png')"
                :sources="[require('@/assets/portfolio/impactee/Gm7vUKbqom.png'), require('@/assets/portfolio/impactee/Gcm6iuDVJ9.png'), require('@/assets/portfolio/impactee/SiGnFBZHnB.png'), require('@/assets/portfolio/impactee/W38UX5nQas.png'), require('@/assets/portfolio/impactee/WDIFEYNMGx.png')]" />

            <!-- <PortfolioWeb :link="false" :title="'JMeter Reporter'"
                :source-image="require('@/assets/portfolio/drorlej/ztRgwdmOn1.png')"
                :sources="[require('@/assets/portfolio/drorlej/ztRgwdmOn1.png')]" /> -->

            <PortfolioWeb :link="true" :link-url="'https://www.drorlej.com/'" :title="'Dr. Orlej'"
                :source-image="require('@/assets/portfolio/drorlej/ztRgwdmOn1.png')"
                :sources="[require('@/assets/portfolio/drorlej/ztRgwdmOn1.png'), require('@/assets/portfolio/drorlej/8bZJjQPb52.png'), require('@/assets/portfolio/drorlej/ceMSqLoizS.png')]" />
        </div>

        <div class="top-0 flex flex-col items-center justify-center mt-16 mb-6 text-gray-200">
            <p
                class="col-span-2 py-1 my-1 text-4xl font-extrabold text-left text-transparent text-gray-200 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 md:mt-10">
                GRAPHICS</p>
            <p class="mx-4 italic text-center">I am not a graphic designer, but I can do some :)</p>
            <p class="col-span-2 py-1 my-1 text-lg italic font-extrabold text-gray-500 md:mt-1">
                Click for more screenshots</p>
        </div>

        <div class="flex flex-wrap items-end justify-center w-full gap-10 text-gray-200">
            <PortfolioGraphics :source-image="require('@/assets/portfolio/graphics/ada/predna_en.png')"
                :orientation="'landscape'"
                :sources="[require('@/assets/portfolio/graphics/ada/predna_en.png'), require('@/assets/portfolio/graphics/ada/zadna_en.png'), require('@/assets/portfolio/graphics/ada/pozvanka_en.png')]" />

            <PortfolioGraphics :source-image="require('@/assets/portfolio/graphics/dano/pokus5.png')"
                :orientation="'portrait'" :sources="[require('@/assets/portfolio/graphics/dano/pokus5.png')]" />

            <PortfolioGraphics :source-image="require('@/assets/portfolio/graphics/miso/oznamenie2.png')"
                :orientation="'portrait'"
                :sources="[require('@/assets/portfolio/graphics/miso/oznamenie2.png'), require('@/assets/portfolio/graphics/miso/pozvanka.png')]" />

            <PortfolioGraphics :source-image="require('@/assets/portfolio/graphics/nase/oznamenie.png')"
                :orientation="'portrait'"
                :sources="[require('@/assets/portfolio/graphics/nase/oznamenie.png'), require('@/assets/portfolio/graphics/nase/pozvanka_predna_strana.png'), require('@/assets/portfolio/graphics/nase/pozvanka_zadna_strana.png')]" />

            <PortfolioGraphics :source-image="require('@/assets/portfolio/graphics/smidy/oznamenie.png')"
                :orientation="'landscape'"
                :sources="[require('@/assets/portfolio/graphics/smidy/oznamenie.png'), require('@/assets/portfolio/graphics/smidy/pozvanka.png'), require('@/assets/portfolio/graphics/smidy/etiketa.png')]" />
        </div>

        <div class="flex flex-col items-center justify-center mt-16 mb-5 text-gray-200 lg:flex-row">
            <router-link to="career"
                class="min-[230px]:max-[370px]:text-sm text-gray-200 border rounded-lg px-5 py-2 font-semibold bg-gray-800 hover:bg-gray-200 hover:text-gray-800">Check
                out my Career Path</router-link>
        </div>
    </div>
</template>

<script>
import PortfolioWeb from "@/components/PortfolioWeb.vue";
import PortfolioGraphics from "@/components/PortfolioGraphics.vue";

export default {
    components: { PortfolioWeb, PortfolioGraphics },
    created() {
        window.scrollTo(0, 0);
    },
}
</script>