<template >
    <div class="top-0 flex flex-col items-center justify-center text-gray-200">
        <p
            class="col-span-2 py-1 my-1 text-4xl font-extrabold text-left text-transparent text-gray-200 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 md:mt-10">
            EMPLOYERS</p>
        <p class="col-span-2 py-1 my-1 text-lg italic font-extrabold text-gray-500 md:mt-1">
            Click for more details</p>

        <select v-model="activeEmployer" class="w-3/4 mt-5 bg-gray-800 rounded-xl md:hidden">
            <option v-for="employer in employers" :key="employer.name" :value="employer.name">{{ employer.name }}</option>
        </select>

        <div class="hidden md:flex md:gap-x-24 md:gap-y-4 md:items-center md:justify-center md:flex-wrap md:mt-10">
            <img v-for="employer in employers" :key="employer.name" :src="require(`@/assets/${employer.image}`)"
                class="duration-300 hover:grayscale-0 hover:cursor-pointer aspect-auto w-44"
                :class="{ 'grayscale': !employer.active, 'opacity-50': !employer.active, 'scale-150': employer.active, 'hover:scale-110': !employer.active }"
                @click="setActiveEmployer(employer.name)" />
        </div>

        <img :src="require(`@/assets/${activeEmployerData.image}`)" class="my-10 aspect-auto w-44 md:hidden" />
        <p
            class="col-span-2 py-1 my-1 text-4xl font-extrabold text-left text-transparent text-gray-200 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 md:mt-10">
            PROJECTS</p>

        <div class="flex items-center justify-center mt-5 mb-5 md:mb-1 md:mt-10">
            <ol class="relative mx-5 bg-gray-700 border-l border-gray-200 bg-opacity-40 rounded-xl">
                <li v-for="(project, index) in activeEmployerData.projects" :key="index"
                    class="px-5 py-5 hover:bg-gray-800 hover:bg-opacity-80">
                    <div class="absolute w-3.5 h-3.5 bg-emerald-400 rounded-full mt-2 -left-2 border border-emerald-400">
                    </div>
                    <time class="mb-1 text-lg font-bold text-emerald-400">{{
                        project.date }}</time>
                    <h3 class="mt-1 text-lg font-bold break-words text-emerald-400">
                        Project Name: {{
                            project.name }}</h3>
                    <h3 class="max-w-3xl mt-1 text-lg font-semibold break-words">Position: <span class="font-normal">{{
                        project.position }}</span></h3>
                    <h3 class="max-w-3xl mt-1 text-lg font-semibold break-words">Description: <span class="font-normal">{{
                        project.description }}</span></h3>
                    <h3 class="max-w-3xl mt-1 text-lg font-semibold break-words">Technologies: <span class="font-normal">{{
                        project.technologies }}</span></h3>
                </li>
            </ol>
        </div>
    </div>

    <div class="flex flex-col items-center justify-center m-auto mb-5 text-gray-200 md:flex-row">
        <router-link to="about"
            class="px-5 py-2 font-semibold text-gray-200 bg-gray-800 border rounded-lg md:mt-8 hover:bg-gray-200 hover:text-gray-800">Learn
            More About Me</router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {
            employers: [
                {
                    name: "Krone Consulting s.r.o.",
                    position: "Technical Test Consultant",
                    image: 'kroneit_red_logo.webp',
                    active: true,
                    projects: [
                        {
                            name: "eeVRee",
                            date: "12/2022 - Now",
                            position: "Frontend Developer",
                            description: "Development of Admin UI console using Vue.JS framework and Tailwind CSS",
                            technologies: "HTML, Vue.JS, Tailwind CSS, Vuex, Axios, JavaScript, Gitlab"
                        },
                        {
                            name: "Thermo Fisher Scientific",
                            date: "09/2022 - Now",
                            position: "Technical Test Consultant",
                            description: "Test Automation, CI/CD and DevOps consultant, Fullstack Developer",
                            technologies: "Azure DevOps, Python, Bash, JavaScript, GNUPlot, Kubernetes, Docker, ArgoCD, K6, Grafana, JMeter, Groovy, Laravel, Livewire, Vue.JS, Tailwind CSS, K3s"
                        },
                        {
                            name: "Kairos",
                            date: "06/2022 - Now",
                            position: "Fullstack Developer",
                            description: "Frontend, backend and DB development for custom machine learning application",
                            technologies: "PHP, Laravel, Livewire, MySQL, Bootstrap, HTML, CSS, Cytoscape, JavaScript, Gitlab, Vue.JS, Tailwind CSS"
                        },
                        {
                            name: "eeVRee",
                            date: "07/2021 - 12/2022",
                            position: "Backend Developer",
                            description: "Backend development for custom VR/Mobile application",
                            technologies: "Python, Node.JS, JavaScript, SQL, PlayFab, Unreal Engine, Blender, Firebase/Firestore, Gitlab, HTML, CSS"
                        },
                        {
                            name: "Ferratum",
                            date: "03/2021 - 09/2022",
                            position: "Senior Technical Test Analyst",
                            description: "Credit Limit Product API integration",
                            technologies: "JIRA, Java/Junit/RestAssured/Allure, Robot Framework, Python, Git/Bitbucket, Bamboo, SwaggerHub, REST API, Kibana, Camunda, ReportPortal"
                        },
                        {
                            name: "UNIQA ESB",
                            date: "07/2020 - 02/2021",
                            position: "Senior Technical Test Analyst",
                            description: "Integration API testing",
                            technologies: "JIRA/Confluence, Tricentis Tosca, SoapUI, SOAP/REST API"
                        },
                    ]
                },
                {
                    name: "AAS Slovakia s.r.o.",
                    position: "Senior Test Analyst",
                    image: 'aas.png',
                    active: false,
                    projects: [
                        {
                            name: "T-Systems Slovakia s.r.o.",
                            date: "01/2020 - 06/2020",
                            position: "Senior Test Analyst",
                            description: "Testing of tool for end-to-end order and setup of Internet connection",
                            technologies: "TestComplete, Tricentis Tosca, JIRA"
                        },
                    ]
                },
                {
                    name: "Raventia s.r.o.",
                    position: "Senior Test Analyst",
                    image: 'raventia.png',
                    active: true,
                    projects: [
                        {
                            name: "Česká pojišťovna, Česká spořitelna, ČSOB",
                            date: "2017 - 2020",
                            position: "Senior Test Analyst",
                            description: "Testing of Unified client service application, Testing of targeted advertising in Internet banking on Web/Android and providing Good Advices to clients via Internet banking, SMS, Email",
                            technologies: "SAS Real-Time Decision Manager, SoapUI, Groovy, Spira, HP Mercury Quality Centre, JIRA"
                        },
                    ]
                },
                {
                    name: "Hellephant",
                    position: " Senior Tester",
                    image: 'hellephant.png',
                    active: true,
                    projects: [
                        {
                            name: "Hellephant",
                            date: "2017 - 2017",
                            position: "Senior Tester",
                            description: "Testing of custom application for Android, iOS and Web",
                            technologies: "Selenium/Java, TestNG, SoapUI, Hiptest"
                        },
                    ]
                },
                {
                    name: "Ness KE s.r.o.",
                    position: "Tester",
                    image: 'ness.png',
                    active: true,
                    projects: [
                        {
                            name: "NICE Actimize",
                            date: "2015 - 2017",
                            position: "QA Engineer",
                            description: "Testing of Anti-Money Laundering (AML) software",
                            technologies: "MSSQL/Oracle databases, Agile methodology, Batch, Bash, VMWare"
                        },
                    ]
                },
                {
                    name: "T-Systems Slovakia s.r.o.",
                    position: "Storage System Administrator",
                    image: 'tsystems.png',
                    active: true,
                    projects: [
                        {
                            name: "T-Systems Slovakia s.r.o.",
                            date: "2012 - 2015",
                            position: "Storage System Administrator",
                            description: "Support of different, customer specific business applications and infrastructure applications for internal and external customers",
                            technologies: "NetApp and Brocade Operation Systems, quality and reporting tools, Bash, Oracle"
                        },
                    ]
                },
                {
                    name: "IXONOS Slovakia s.r.o.",
                    position: "Test Engineer",
                    image: 'ixonos.png',
                    active: true,
                    projects: [
                        {
                            name: "IXONOS Slovakia s.r.o.",
                            date: "2011 - 2012",
                            position: "Test Engineer",
                            description: "Testing of the newest OS for mobile phones and smartphones (UI, performance, stress, framerate, power consumption)",
                            technologies: "HP Mercury Quality Centre"
                        },
                    ]
                },
            ],
            activeEmployer: ""
        }
    },
    methods: {
        setActiveEmployer(employer) {
            this.activeEmployer = employer;
        }
    },
    computed: {
        activeEmployerData() {
            this.employers.forEach(employer => {
                if (employer.name === this.activeEmployer) {
                    employer.active = true;
                } else {
                    employer.active = false;
                }
            });
            return this.employers.find(employer => employer.name === this.activeEmployer);
        }
    },
    created() {
        this.activeEmployer = this.employers[0].name;
        this.employers[0].active = true;
        window.scrollTo(0, 0);
    }
}
</script>