<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
import { Collapse } from 'flowbite';

onMounted(() => {
  initFlowbite();
})
</script>

<template>
  <div
    class="overflow-auto h-auto sm:h-screen bg-[url('./assets/16264060_rm314-bb-18.jpg')] bg-cover flex flex-col justify-between bg-gray-800 font-mono">
    <header class="">
      <nav class="py-2 text-gray-200 bg-gray-800 border-b border-gray-500 bg-opacity-60">
        <div class="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">

          <!-- <h1 class="text-2xl font-extrabold leading-none tracking-tight text-gray-200"><router-link to="/" @click="toggleMenu()">Dominik <span class="px-1 text-gray-200">Szabo</span></router-link></h1> -->
          <p
            class="col-span-2 py-1 text-2xl font-extrabold text-left text-transparent text-gray-200 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            <!-- <router-link to="/" @click="toggleMenu()">
              Dominik Szabo</router-link> -->
            <router-link to="/" @click="toggleMenu()"><img :src="require(`@/assets/yawz1k0AQ4.png`)"
                class="w-64 duration-300 aspect-auto hover:scale-110" /></router-link>
          </p>

          <button id="triggerEl" data-collapse-toggle="navbar-default" type="button"
            class="inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-gray-200 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div class="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul
              class="flex flex-col p-4 mt-4 text-lg font-medium border-t border-b md:p-0 md:flex-row md:space-x-8 md:mt-0 md:border-0">
              <li>
                <router-link to="/" @click="toggleMenu()"
                  class="block py-2 pl-3 pr-4 text-gray-200 rounded hover:bg-gray-100 hover:text-gray-800 md:hover:bg-transparent md:border-0 md:hover:text-sky-400 md:p-0 ">Home</router-link>
              </li>
              <li>
                <router-link to="offer" @click="toggleMenu()"
                  class="block py-2 pl-3 pr-4 text-gray-200 rounded hover:bg-gray-100 hover:text-gray-800 md:hover:bg-transparent md:border-0 md:hover:text-sky-400 md:p-0 ">What Do I Offer</router-link>
              </li>
              <li>
                <router-link to="career" @click="toggleMenu()"
                  class="block py-2 pl-3 pr-4 text-gray-200 rounded hover:bg-gray-100 hover:text-gray-800 md:hover:bg-transparent md:border-0 md:hover:text-sky-400 md:p-0 ">Career</router-link>
              </li>
              <li>
                <router-link to="about" @click="toggleMenu()"
                  class="block py-2 pl-3 pr-4 text-gray-200 rounded hover:bg-gray-100 hover:text-gray-800 md:hover:bg-transparent md:border-0 md:hover:text-sky-400 md:p-0 ">About</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <main class="my-2" @click="toggleMenu()">
      <router-view />
    </main>

    <footer
      class="flex flex-row items-start justify-center py-4 text-gray-200 bg-gray-800 border-t border-gray-500 bg-opacity-60 gap-x-8">
      <div class="font-semibold text-center sm:flex sm:items-center sm:justify-between gap-x-4">
        <span class="mt-1 text-sm text-gray-200 sm:text-center">© {{ getCurrentYear }} <a href="https://dominikszabo.sk/"
            class="hover:underline">Dominik Szabo</a>
        </span>
        <div class="flex items-center justify-center mt-4 space-x-5 sm:mt-0">
          <a href="https://www.linkedin.com/in/dominik-szab%C3%B3-a75689138/" target="_blank"
            class="pb-1 text-gray-200 hover:text-sky-600">
            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 15 15">
              <path fill-rule="evenodd"
                d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                clip-rule="evenodd" />
              <path d="M3 5.012H0V15h3V5.012Z" />
            </svg>
          </a>

          <a href="mailto:dominikszabo@dominikszabo.sk" class="text-gray-200 hover:text-sky-600">
            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2" />
            </svg>
          </a>

        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    toggleMenu() {
      // set the target element that will be collapsed or expanded (eg. navbar menu)
      const $targetEl = document.getElementById('navbar-default');

      // optionally set a trigger element (eg. a button, hamburger icon)
      const $triggerEl = document.getElementById('triggerEl');

      // optional options with default values and callback functions
      const options = {
      };

      const collapse = new Collapse($targetEl, $triggerEl, options);

      collapse.collapse();
    }
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>